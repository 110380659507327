import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const Container = styled.div`
  background: #ffffff;
  border-radius: 3px;
  overflow: hidden;
  display: block;

  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */

  .image-container {
    height: 286px;
    width: 100%;
    background-size: cover;
    background-position: center;
    display: block;
    position: relative;

    background-color: #9E9E9E;

    &:hover .hover-image {
      opacity: 1;
      visibility: visible;
    }

    .hover-image {
      opacity: 0;
      visibility: hidden;

      transition: .3s all ease;

      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      background: rgba(0, 0, 0, 0.5);
      padding: 15px 14px 10px;
      padding-top: 60px;

      display: flex;
      align-items: flex-end;
      justify-content: flex-end;

      .view-project {
        display: flex;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }

      .edit-project {
        background: #f1a857;
        border-radius: 3px;
        width: 50px;
        height: 50px;

        display: flex;
        align-items: center;
        justify-content: center;

        transition: .2s all ease;
        z-index: 1;

        i {
          background: url('/images/streamline-icon-pencil-write-2-140-x-140.png') center no-repeat;
          background-size: 24px;
          width: 24px;
          height: 24px;
          display: block;
        }

        &:hover {
          background: #E59134;
        }
      }
    }
  }

  @media (min-width: 767px) {
    .image-container {
      height: 265px;

      .hover-image {
        padding-top: 45px;
      }
    }
  }

  @media (min-width: 1200px) {
    .image-container {
      height: 270px;

      .hover-image {
        padding-top: 55px;
      }
    }
  }

  .project-info {
    padding: 20px 15px 11px;

    h3 {
      font: italic bold 20px 'Times new roman';
      color: #323232;
      margin-bottom: 20px;
      text-align: left;
    }

    & > span {
      display: block;
      font-size: 12px;
      color: #959595;
      text-align: left;
    }

    .project-info-footer {
      margin-top: 18px;
      padding-top: 8px;
      border-top: 1px solid #F4F7F6;
      
      display: flex;
      align-items: center;
      justify-content: space-between;

      .author {
        display: flex;
        align-items: center;

        img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }

        h4 {
          font: italic bold 13px 'Times new roman';
          color: #323232;
          margin-left: 10px;
        }
      }

      .likes {
        display: flex;
        align-items: center;

        i {
          margin-right: 7px;
          width: 20px;
          height: 20px;
          background: url('/images/heart.svg') no-repeat center;
          background-size: contain;
        }

        p {
          font-size: 12px;
          color: #959595;
          margin: 0;
        }
      }
    }
  }
`;

export default function ProjectCardLogged({
  id,
  image,
  name,
  city,
  uf,
  authorImage,
  authorName,
  numberOfLikes
}) {
  const gallery = image !== null && image !== "" && image.length > 2 ? JSON.parse(image)[0].url : null;

  return (
    <Container>
      <div
        className="image-container"
        style={
          gallery ? {backgroundImage: `url(${gallery})`} : null
        }
      >
        <div className="hover-image">
          <Link
            to={`/ambiente/${id}`}
            state={{
              modal: true
            }}
            className="view-project"
          />
          <Link
            to={`/editar-ambiente/${id}`}
            state={{
              modal: true
            }}
            className="edit-project"
          >
            <i></i>
          </Link>
        </div>
      </div>
      <div className="project-info">
        <h3>{name}</h3>
        <span>{city}, <i>{uf}</i></span>

        <div className="project-info-footer">
          <div className="author">
            <img src={authorImage} alt={authorName} />
            <h4>{authorName}</h4>
          </div>

          <div className="likes">
            <i></i>
            <p>{numberOfLikes}</p>
          </div>
        </div>
      </div>
    </Container>
  )
}