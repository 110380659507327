import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import ProjectCardLogged from "../../components/project-card-logged";

const Container = styled.div`
  .projects-list-approved {
    li {
      margin-bottom: 35px;
    }

    .card-publish {
      background: #ffffff;
      border-radius: 3px;
      height: 100%;
      padding: 16px;
      display: flex;

      transition: .2s all ease;

      div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        width: 100%;
        min-height: 430px;

        border: dashed 2px #f1a857;
        border-radius: 3px;
      }

      i {
        width: 54px;
        height: 43px;
        background: url('/images/icon_upload-black.svg') no-repeat center;
        margin-bottom: 20px;
        background-size: contain;
        display: block;
      }

      h4 {
        font: italic bold 20px 'Times new roman';
        color: #323232;
      }

      &:hover {
        opacity: .6;
      }
    }
  }

  @media (min-width: 767px) {
    .projects-list-approved .card-publish div {
      min-height: 400px;
    }
  }

  @media (min-width: 1200px) {
    .projects-list-approved {
      padding-top: 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 16px;
      grid-row-gap: 28px;

      li {
        margin-bottom: 0;
      }

      .card-publish div {
        min-height: 384px;
      }
    }
  }
`;

export default function TabApproved({ dataProjects }) {
  return (
    <Container>
      <ul className="projects-list-approved">
        <li>
          <Link
            to="/criar-ambiente"
            state={{
              modal: true
            }}
            className="card-publish"
          >
            <div>
              <i></i>
              <h4>Publique seu Projeto</h4>
            </div>
          </Link>
        </li>
        {dataProjects.length > 0 && (
          dataProjects.map((project, index) => (
            <li key={index}>
              <ProjectCardLogged
                id={project.id}
                image={project.gallery}
                name={project.name}
                city={project.city}
                uf={project.uf}
                authorImage={project.user.company_avatar !== null ? project.user.company_avatar : "/images/01.png"}
                authorName={project.user.company_name}
                numberOfLikes={project.likes}
              />
            </li>
          ))
        )}
      </ul>
    </Container>
  )
}