import React from "react";
import styled from 'styled-components';
import { Link } from 'gatsby';

const Container = styled(Link)`
  width: auto;
  height: 60px;
  border-radius: 3px;

  background: #F1A857;

  display: flex;
  align-items: center;

  transition: .2s all ease;

  span {
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    padding-left: 29px;
    padding-right: 17px;

    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  i {
    width: 55px;
    height: 100%;
    border-left: 1px solid #D99440;
    margin-left: auto;

    &.plus {
      background: url('/images/icon-plus-white.svg') center no-repeat;
    }
    &.arrow-right {
      background: url('/images/arrow-right.png') center no-repeat;
    }
    &.edit-image {
      background: url('/images/streamline-icon-image-file-edit-140-x-140.png') center no-repeat;
    }
    &.edit-profile {
      background: url('/images/streamline-icon-pencil-write-2-140-x-140.png') center no-repeat;
    }
  }

  &:hover {
    background: #E59134;

    i {
      border-left: 1px solid #CD8226;
    }
  }
`;

export default function Button({
  to,
  name,
  iconName,
  ...rest
}) {
  return (
    <Container
      to={to}
      {...rest}
    >
      <span>{name}</span>
      <i className={iconName}></i>
    </Container>
  );
}

