import React from 'react';
import styled from 'styled-components';

import ProjectCardFavorite from "../../components/project-card-favorite";

const Container = styled.div`
  h2 {
    font: italic bold 30px 'Times new roman';
    color: #000000;
    text-align: center;
  }

  .projects-list-favorites {
    li {
      margin-bottom: 35px;
    }

    .card-publish {
      background: #ffffff;
      border-radius: 3px;
      height: 100%;
      padding: 16px;
      display: flex;

      transition: .2s all ease;

      div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        width: 100%;
        min-height: 430px;

        border: dashed 2px #f1a857;
        border-radius: 3px;
      }

      i {
        width: 54px;
        height: 43px;
        background: url('/images/icon-publish.svg') no-repeat center;
        margin-bottom: 20px;
        background-size: contain;
        display: block;
      }

      h4 {
        font: italic bold 20px 'Times new roman';
        color: #323232;
      }

      &:hover {
        opacity: .6;
      }
    }
  }

  @media (min-width: 767px) {
    .projects-list-favorites .card-publish div {
      min-height: 400px;
    }
  }

  @media (min-width: 1200px) {
    .projects-list-favorites {
      padding-top: 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 16px;
      grid-row-gap: 28px;

      li {
        margin-bottom: 0;
      }

      .card-publish div {
        min-height: 384px;
      }
    }
  }
`;

export default function TabFavorites({ dataFavorites }) {
  return (
    <Container>
      {dataFavorites.length > 0 && (
        <ul className="projects-list-favorites">
          {dataFavorites.map((favorite, index) => (
            <li key={index}>
              <ProjectCardFavorite
                id={favorite.project.id}
                image={favorite.project.gallery}
                name={favorite.project.name}
                city={favorite.project.city}
                uf={favorite.project.uf}
                authorImage={favorite.user.company_avatar !== null ? favorite.user.company_avatar : "/images/01.png"}
                authorName={favorite.user.company_name}
                numberOfLikes={favorite.project.likes}
                products={favorite.project.products}
              />
            </li>
          ))}
        </ul>
      )}
    </Container>
  )
}