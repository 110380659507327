import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { AuthContext } from "../../context/auth";

import api from "../../services/api";

import Header from "../../components/header";
import Newsletter from "../../components/newsletter";
import Footer from "../../components/footer";
import ContainerDefault from "../../components/container";

import ProfileProjects from "./projects";
import ProfileSidebar from "./sidebar";
import { navigate } from "gatsby";

const Container = styled.div`
  .header-bg {
    height: 250px;
    background-color: #9e9e9e;

    background-size: cover;
    background-position: center;

    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    color: #fff;

    .button-file {
      margin-left: auto;
      width: 58px;
      height: 50px;
      margin-bottom: 90px;
      cursor: pointer;

      border-radius: 3px;
      border: 3px solid #f1a857;
      background: #f1a857;

      display: flex;
      align-items: center;

      transition: 0.2s all ease;

      input[type="file"] {
        display: none;
      }

      span {
        display: none;
      }

      i {
        width: 55px;
        height: 100%;
        margin-left: auto;
        background: url("/images/streamline-icon-image-file-edit-140-x-140.png")
          center no-repeat;
        background-size: 24px;
        border-left: 0;
      }
    }

    @media (min-width: 767px) {
      height: 315px;

      .button-file {
        cursor: pointer;
        width: 230px;
        margin-bottom: 20px;

        span {
          color: #ffffff;
          font-size: 14px;
          font-weight: 500;
          padding-left: 29px;
          padding-right: 17px;

          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        i {
          width: 55px;
          height: 100%;
          border-left: 1px solid #d99440;
          margin-left: auto;
          background: url("/images/streamline-icon-image-file-edit-140-x-140.png")
            center no-repeat;
          background-size: 24px;
        }

        &:hover {
          background: #f1a857;

          span {
            color: #ffffff;
          }

          i {
            border-left: 1px solid #e0933d;
          }
        }
      }
    }

    @media (min-width: 1200px) {
      height: 300px;
    }
  }

  @media (min-width: 767px) {
    padding-bottom: 35px;

    .content-profile {
      display: flex;

      .sidebar-profile {
        max-width: 379px;
        width: 100%;
      }

      .projects-profile {
        flex: 1;
        margin-left: 16px;
      }
    }
  }

  @media (min-width: 1200px) {
    padding-bottom: 85px;
  }
`;

export default function MyProfile(props) {
  const { isLoggedIn, getUser, signIn } = useContext(AuthContext);
  const [favorites, setFavorites] = useState([]);
  const [inactives, setInactives] = useState([]);
  const [projects, setProjects] = useState([]);
  const [backgroundImage, setBackgroundImage] = useState(null);

  async function loadUser() {
    if (getUser == null) {
      await navigate("/login");
    } else {
      setBackgroundImage(getUser.background);

      const likes = await api.get(
        `https://apimeuambiente.guararapes.com.br/user/${getUser.id}/likes/`
      );
      setFavorites(likes.data.favorites);

      const inactives = await api.get(
        `https://apimeuambiente.guararapes.com.br/user/${getUser.id}/projects/inactive/`
      );
      setInactives(inactives.data);

      const actives = await api.get(
        `https://apimeuambiente.guararapes.com.br/user/${getUser.id}/projects/`
      );
      setProjects(actives.data);
    }
  }

  useEffect(() => {
    loadUser();
  }, [getUser]);

  async function handleSelectBackground(event) {
    if (!event.target.files) {
      return;
    }

    const data = new FormData();

    data.append("file[0]", event.target.files[0]);

    const response = await api.post(
      "https://apimeuambiente.guararapes.com.br/upload/",
      data
    );

    const responseBackground = await api.post(
      `https://apimeuambiente.guararapes.com.br/user/${getUser.id}/background/update/`,
      {
        background: response.data.imgs[0].url,
      }
    );

    setBackgroundImage(response.data.imgs[0].url);

    signIn(responseBackground.data.user);
  }

  if (!getUser) return <></>;
  return (
    <>
      <Header />
      {isLoggedIn ? (
        <Container>
          <div
            className="header-bg"
            style={
              backgroundImage !== null
                ? { backgroundImage: `url(${backgroundImage})` }
                : null
            }
          >
            <ContainerDefault>
              <label htmlFor="picture-file" className="button-file">
                <input
                  type="file"
                  name=""
                  id="picture-file"
                  onChange={handleSelectBackground}
                />
                <span>Editar capa</span>
                <i></i>
              </label>
            </ContainerDefault>
          </div>

          <ContainerDefault>
            <div className="content-profile">
              <div className="sidebar-profile">
                <ProfileSidebar getUser={getUser} />
              </div>
              <div className="projects-profile">
                <ProfileProjects
                  data={favorites}
                  dataInactives={inactives}
                  dataProjects={projects}
                />
              </div>
            </div>
          </ContainerDefault>
        </Container>
      ) : (
        <p>nao logado</p>
      )}
      <Newsletter />
      <Footer />
    </>
  );
}
